// consolidate chapter 7 and 13 here

import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import { Container, Row, Col, CardDeck, Card, Button } from "react-bootstrap"
import ContactForm from "../../../components/contact-us-form"
import PageHeader from "../../../components/page-header"
import PracticeAreas from "../../../components/practice-areas"

export default () => (
    <Layout style={{ color: `teal` }}>
        <Helmet title="Chapter 7 Bankruptcy Lawyers | RWK Attorneys" defer={false} />


        <PageHeader>
            <Row>
                <Col>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-0">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
                            <li class="breadcrumb-item"><Link to="/practice-areas/bankruptcy">Bankruptcy</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Chapter 7</li>
                        </ol>
                    </nav>
                    <h1>Consumer<br /><small>Bankruptcy Law</small></h1>
                </Col>
            </Row>
        </PageHeader>


        <Container className="my-5">
            <Row>
                <Col md="3">
                    <PracticeAreas />

                    <ContactForm />
                </Col>
                <Col>
                    <h1>Consumer bankruptcy Fundamentals</h1>

                    <p>Consumer debt is created by debt accumulated by personal expenses such as mortgage or rent payments, loans (student loans, car loans, home equity loans), insurance (car insurance, health insurance, life insurance), daycare, tuition, and utilies to name a few.</p>

                    <p>Sooner or later, many of us will fall behind on monthly payments and begin to consider bankruptcy as an option to find relief from this burden. Consumer bankruptcy refers to a type of bankruptcy filed to reduce this type of debt obligation.</p>

                    <h2>Types of Consumer Bankruptcy</h2>
                    <p>We deal with several different types of bankruptcy filings each day. Each type has its advantages and disadvantages; however, most people in Alabama will file for Chapter 13. We will evaluate your situation with you to help you determine the best filing method for you.</p>

                    <CardDeck>

                        <Card>
                            <Card.Body>
                                <Card.Title>Chapter 7</Card.Title>
                                <Card.Text>
                                    <p>Eliminate your debt without needing to repay it. Keep your home, cars and other property while still eliminating debt.</p>
                                </Card.Text>
                            </Card.Body>
                            <Button variant="secondary" className="stretched-link" as={Link} to="/practice-areas/bankruptcy/chapter-7">Learn More</Button>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>Chapter 13</Card.Title>
                                <Card.Text>
                                    Pay back all or a portion of your debt over a set period of time, up to five (5) years. Gives you a chance to repay or catch up debt that has become overwhelming.
                                </Card.Text>
                            </Card.Body>
                            <Button variant="secondary" className="stretched-link" as={Link} to="/practice-areas/bankruptcy/chapter-13">Learn More</Button>
                        </Card>
                    </CardDeck>

                </Col>
            </Row>

        </Container>
    </Layout >
)